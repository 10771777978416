import React from 'react'
import Slider from 'rc-slider';


const FeedbackRating = ({question,onChange,vall,options}) => {
  
  return (
    <div className='sliderRating'>
        {question}
        <div className='customSlider mt-40'>
          <Slider step={'20'} value={vall*20} onChange={onChange}/>        
          
          <div className='rangeCount mt-15'>
            {options?.map((val,i)=> <span className={`${val===vall?"active":""}`} key={i}>{val}</span>)}  
            
          </div>
        </div>
    </div>
  )
}

export default FeedbackRating 
