import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { Calendar } from "react-calendar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import PageLoader from "../../../components/PageLoader/PageLoader";
import ErrorToast from "../../../components/Toast/ErrorToast";
import { clearState, getSlots, mentorSliceData } from "../../../reducer/mentorSlice";
import "./MentorSlotsStyle.css";

const MentorSlots = () => {
  const { slots, isFetching,isError, errorMessage } = useSelector(mentorSliceData);
  const [errMessage, seterrMessage] = useState("");
  const [isShowError, setIsShowError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const obj = {
      date: moment(date).format("YYYY-MM-DD"),
    };
    dispatch(getSlots(obj));
  }, [date]);

  useEffect(()=>{
    if (isError) {
      setIsShowError(true);
      seterrMessage(errorMessage);
      dispatch(clearState());
    }
  },[isError])

  return (
    <>
      <ErrorToast
            show={isShowError}
            setShow={() => setIsShowError(false)}
            message={errMessage}
          />
      {isFetching && <PageLoader />}
      <div className="mentorSlotsWrapper">
        <div className="profileHeader">
          <h2 className="heading-3">My Profile</h2>
          <Button
            className={"outlineBtn"}
            name={"Set your availability"}
            onClick={() => navigate("/editSlots")}
          />
        </div>

        <div className="timeSlotWrap">
          <div className="leftSlot">
            <Calendar onChange={(e) => setDate(e)} minDate={new Date()} />
          </div>

          <div className="rightSlot">
            <div className="slotHeading">Timing</div>

            <div className="formGroup profileRadioGroup mt-10">
              <div className="feedbackRadioGroup">
                {slots?.length > 0 ? slots?.map((time, i) => (
                  <div className="radioBtnOuter" key={i}>
                    <input
                      type="radio"
                      id={`test1${i + 1}`}
                      name="radio-group"
                      disabled
                    />
                    <label for={`test1${i + 1}`}>{time?.time}</label>
                  </div>
                )) : <b>No slot added</b>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MentorSlots;
