import React, { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import "./CreateFeedbackStyle.css";
import FeedbackQuestion from "./FeedbackQuestion";
import FeedbackRating from "./FeedbackRating";
import FeedbackOption from "./FeedbackOption";
import { useDispatch, useSelector } from "react-redux";
import {
  mentorSliceData,
  saveMentorFeedbackAnswers,
  clearState,
} from "../../../reducer/mentorSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { completeInterview } from "../../../reducer/interviewSlice";
import ErrorToast from "../../../components/Toast/ErrorToast";
import PageLoader from "../../../components/PageLoader/PageLoader";
// import ErrorToast from "../../../components/Toast/ErrorToast";

const CreateFeedback = () => {
  const { isFetching, isSuccess, isError, errorMessage } = useSelector(mentorSliceData);
  const [errMessage, setErrMessage] = useState("");
  const [isShowError, setIsShowError] = useState(false);
  const [experince, setExperince] = useState(0);
  const [actionPlan, setActionPlan] = useState("");
  const [action, setAction] = useState("");
  const [otherValue, setOtherValue] = useState("");
  const [index, setIndex] = useState(0);
  const [employability, setEmployability] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const bookingId = searchParams.get("id") ?? "";

  const saveMentorFeedback = () => {
    const payload = {
      answer1: experince,
      answer2: actionPlan !== "Others" ? actionPlan : otherValue,
      answer3: action,
      answer4: employability,
    };
    if (index === 3 && employability !== null) {
      const obj = {
        payload,
        bookingId,
      };
      dispatch(saveMentorFeedbackAnswers(obj));
    }
  };

  const optionsNoQuestion2 = [
    "Looking for a new job/career switch",
    "Feel stagnated in their current position",
    "Unsure about what to do after graduation",
  ];
  const options = [0, 1, 2, 3, 4, 5];

  useEffect(() => {
    if (isSuccess) {
      navigate("/", {replace: true});
      dispatch(clearState());
      // window.location.reload()
    }
    if (isError) {
      setIsShowError(true);
      setErrMessage(errorMessage);
      dispatch(clearState());
    }
  }, [isSuccess, isError]);

  // useEffect(() => {
  //   if (bookingId) {
  //     const obj = {
  //       bookingId,
  //     };
  //     dispatch(completeInterview(obj));
  //   }
  // }, []);

  const handleNext = () => {
    if (
      (index === 0 && experince !== null) ||
      (index === 1 &&
        (actionPlan !== "Others" ? actionPlan : otherValue) !== "") ||
      (index === 2 && action !== "")
    ) {
      setIndex((prev) => (prev !== 3 ? prev + 1 : 3));
    } else if (index === 3 && employability !== null) {
      saveMentorFeedback();
    } else {
      setIsShowError(true);
      setErrMessage("Feedback is mandatory");
    }
  };

  return (
    <>
      {isFetching && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <div className="createFeedbackWrapper">
        <div className="feedbackWrapBox">
          <div className="feedbackLeft">
            {index === 0 && (
              <FeedbackRating
                question={
                  <div className="giveFeedbackQuestion">
                    How was your <span>experience</span> with the{" "}
                    <span>mentee</span>?
                  </div>
                }
                onChange={(e) => setExperince(e / 20)}
                vall={experince}
                options={options}
              />
            )}
            {/* { index==0 && <FeedbackQuestion ques={<div className="giveFeedbackQuestion">Did the call <span>help you</span> get any <span>clarity</span>?</div>}  options={["Yes","No","A little bit"]}/>} */}
            {index == 1 && (
              <FeedbackOption
                ques={
                  <div className="giveFeedbackQuestion">
                    What was the mentee's <span>problem</span> about?
                  </div>
                }
                onChange={setActionPlan}
                vall={actionPlan}
                otherValue={otherValue}
                setOtherValue={setOtherValue}
                options={optionsNoQuestion2}
              />
            )}
            {index == 2 && (
              <FeedbackQuestion
                ques={
                  <div className="giveFeedbackQuestion">
                    Were you able to <span>come up</span> with an{" "}
                    <span>action plan</span> for the mentee?
                  </div>
                }
                options={["Yes", "No", "Maybe"]}
                onChange={setAction}
                vall={action}
              />
            )}
            {index == 3 && (
              <FeedbackRating
                question={
                  <div className="giveFeedbackQuestion">
                    How would you <span>rate</span> the mentee's{" "}
                    <span>employability</span>, if you were <span>to hire</span>{" "}
                    them for your own team?
                  </div>
                }
                onChange={(e) => setEmployability(e / 20)}
                vall={employability}
                options={options}
              />
            )}
            <div className="fedbackButtonWrap">
              {index !== 0 ? (
                <Button
                  className={"feedbackBtnWhite imgButton"}
                  name={"Back"}
                  src={"/assets/icons/feedbackPrev.png"}
                  onClick={() => {
                    setIndex((prev) => (prev !== 0 ? prev - 1 : 0));
                  }}
                />
              ) : <div style={{width:"79px"}}>  </div>}
              <div className="navigationWrap ">
                <span
                  className={`navigationDot ${index >= 0 ? "active" : ""}`}
                ></span>
                <span
                  className={`navigationDot ${index >= 1 ? "active" : ""}`}
                ></span>
                <span
                  className={`navigationDot ${index >= 2 ? "active" : ""}`}
                ></span>
                <span
                  className={`navigationDot ${index >= 3 ? "active" : ""}`}
                ></span>
                {/* <span className="navigationDot"></span> */}
              </div>
              <Button
                className={"feedbackBtnBlack"}
                name={`${index === 3 ? "Submit" : "Next"}`}
                onClick={(prev) => handleNext(prev)}
              />
            </div>
          </div>
          <div className="feedbackRight">
            <img src="/assets/img/feedbackBG.png" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateFeedback;
