import React from "react";
import "./MentorFeedbackStyle.css";
import Button from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearState,
  getAllPastCalls,
  mentorSliceData,
} from "../../../reducer/mentorSlice";
import { useEffect } from "react";
import PageLoader from "../../../components/PageLoader/PageLoader";
import moment from "moment/moment";
import ErrorToast from "../../../components/Toast/ErrorToast";
import { useState } from "react";

const MentorFeedback = () => {
  const { allPastCalls, isFetching,isError, erroMessage } = useSelector(mentorSliceData);
  const [errMessage, seterrMessage] = useState("");
  const [isShowError, setIsShowError] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllPastCalls());
  }, []);

  useEffect(()=>{
    if (isError) {
      setIsShowError(true);
      seterrMessage(erroMessage);
      dispatch(clearState());
    }
  },[isError])

  return (
    <div className="feedbackWrapper">
      {isFetching && <PageLoader />}
      <ErrorToast
            show={isShowError}
            setShow={() => setIsShowError(false)}
            message={errMessage}
          />
      <div className="profileHeader">
        <h2 className="heading-3">Feedback Received</h2>
      </div>

     
        <div className="scheduleData feedbackData">
       
          <div className="tabCall active" >
          {allPastCalls?.length > 0 ? allPastCalls?.map((value, index) => (
            <div className={`callersBox ${index && "mt-20"}`} key={index}>
              
              <div className="callersBoxLeft">
                <img src={"/assets/img/profiledefault.png"} alt="" />
                <div className="callDetail">
                  <div className="callerName">
                    {value.GetPreppedMentee?.firstName ? `${value.GetPreppedMentee?.firstName} ${value.GetPreppedMentee?.lastName}` : "---"}
                  </div>
                  <div className="callerEmail mt-5">
                    {value.GetPreppedMentee?.email ? value.GetPreppedMentee?.email : "---"}
                  </div>
                  <div className="callDetails mt-10">
                    <div className="callBox">
                      {value?.startTime ? moment(value?.startTime).format("ddd DD MMM YYYY") : "---"}
                    </div>
                    <div className="callBox">{value?.time ? value?.time : "---"}</div>
                  </div>
                </div>
              </div>
              <div className="callerBoxRight">
                <Button
                  className={"outlineBtn"}
                  name={"View Feedback"}
                  onClick={() => navigate(`/viewFeedback/${value.id}`)}
                />
              </div>
            </div>
           )): "No feedback received yet"} 
          </div>
          
        </div>
      
    </div>
  );
};

export default MentorFeedback;
