import React from 'react'
import 'rc-slider/assets/index.css';

const FeedbackQuestion = ({ ques, options,onChange, vall}) => {
    return (
        <div className='QuestionWrap'>

            {/* <Range /> */}

            {/* <div className="giveFeedbackQuestion">Did the call <span>help you</span> get any <span>clarity</span>?</div> */}
            {ques}
            <div className="customRadioWrap mt-45">
                {options.map((option, index) =>
                    <label className='customRadio' htmlFor={`text${index + 1}${index + 1}`}>
                        <input type="radio" id={`text${index + 1}${index + 1}`} name='radio' value={option} checked={vall === option ? true : false} onChange={(e)=>onChange(e.target.value)} />
                        <span class="checkmark"></span>
                        {option}

                    </label>)}

                {/* <label className='customRadio' htmlFor="text22">
                    <input type="radio" id='text22' name='radio'/>
                    <span class="checkmark"></span>
                    No
                </label>

                <label className='customRadio' htmlFor="text33">
                    <input type="radio" id='text33' name='radio'/>
                    <span class="checkmark"></span>
                    A little bit
                </label> */}

            </div>

        </div>
    )
}

export default FeedbackQuestion