import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";

let token;
let setTokenValues = () => {
  token = localStorage.getItem("token");
};

// admin/booking/:bookingId/completeInterview
export const completeInterview = createAsyncThunk(
  "interview /completeInterview",
  async ({ bookingId }, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        "/api/getPrepped/admin/booking/" +
        bookingId +
        "/completeInterview",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.statusText === 'Unauthorized') {
        localStorage.clear();
        window.location.reload();
      }
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const interviewSlice = createSlice({
  name: "interview",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [completeInterview.pending]: (state) => {
      state.isFetching = true;
    },
    [completeInterview.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      // state.slots = payload;
      return state;
    },
    [completeInterview.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
  },
});
export const { clearState } = interviewSlice.actions;

export const userSelector = (state) => state.interview;
