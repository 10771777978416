import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";

// api/getPrepped/admin/login

export const loginUsers = createAsyncThunk(
  "login/loginUsers",
  async ({ email, password }, thunkAPI) => {
    try {
      const payload = {
        email: email,
        password: password,
      };
      const response = await fetch(`${environment.baseURL}/api/getPrepped/admin/login`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        localStorage.setItem("token", data.Token);
        localStorage.setItem("mentorId", data.Data.id)
        localStorage.setItem("firstName", data.Data.firstName)
        localStorage.setItem("lastName", data.Data.lastName)
        localStorage.setItem("orgId", "91c3b93c-59ba-44e7-8e99-bd5fc889b68v")
        localStorage.setItem("imgprofile", data.Data.profileImg)
        if(!data.Data.isPassChanged) localStorage.setItem("isPassChanged", data.Data.isPassChanged);

        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "login/forgotPassword",
  async (email, thunkAPI) => {
    try {
      const response = await fetch(`${environment.baseURL}/api/getPrepped/admin/forgotPassword`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );
      if (response.statusText === 'Unauthorized') {
        return thunkAPI.rejectWithValue(response.statusText);
      }
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const changeForgotPassword = createAsyncThunk(
  "login/changeForgotPassword",
  async ({ token, password }, thunkAPI) => {
    try {
      const response = await fetch(`${environment.baseURL}/api/getPrepped/admin/changeForgotPassword`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
          },
          body: JSON.stringify({ password }),
        }
      );
      if (response.statusText === 'Unauthorized') {
        return thunkAPI.rejectWithValue(response.statusText);
      }
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e);
    }
  }
);

export const validateMentor = createAsyncThunk("mentor/validateMentor", async (token,thunkAPI) => {
  try {
      const response = await fetch(environment.baseURL +
          "/api/getPrepped/admin/validateMentor",
          {
              method: "GET",
              headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: token,
              },
          }
      );
      if (response.statusText === 'Unauthorized') {
        return thunkAPI.rejectWithValue("Unauthorized");
      }
      let data = await response.json();
      if (response.status === 200) {
        localStorage.setItem("token", data.Token);
        localStorage.setItem("mentorId", data.Data.id)
        localStorage.setItem("firstName", data.Data.firstName)
        localStorage.setItem("lastName", data.Data.lastName)
        localStorage.setItem("orgId", "91c3b93c-59ba-44e7-8e99-bd5fc889b68v")
        localStorage.setItem("imgprofile", data.Data.profileImg)
        if(!data.Data.isPassChanged) localStorage.setItem("isPassChanged", data.Data.isPassChanged);
          return data;
      } else {
          return thunkAPI.rejectWithValue(data);
      }
  } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
  }
}
);

export const userSlice = createSlice({
  name: "loginUser",
  initialState: {
    LoginData: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [loginUsers.fulfilled]: (state, { payload }) => {
      state.LoginData = payload?.Data;
      state.isFetching = false;
      state.isSuccess = true;
      state.token = payload.Token;
      return state;
    },
    [loginUsers.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [loginUsers.pending]: (state) => {
      state.isFetching = true;
    },
    [validateMentor.fulfilled]: (state, { payload }) => {
      state.LoginData = payload?.Data;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [validateMentor.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ?? "Something Went Wrong";
    },
    [validateMentor.pending]: (state) => {
      state.isFetching = true;
    },
    [changeForgotPassword.fulfilled]: (state) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [changeForgotPassword.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload : "Something Went Wrong";
    },
    [changeForgotPassword.pending]: (state) => {
      state.isFetching = true;
    },
    [forgotPassword.fulfilled]: (state) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [forgotPassword.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload : "Something Went Wrong";
    },
    [forgotPassword.pending]: (state) => {
      state.isFetching = true;
    },
  },
});


export const { clearState } = userSlice.actions;

export const userSelector = (state) => state.user;
