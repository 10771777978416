import React from 'react'
import 'rc-slider/assets/index.css';
import InputField from '../../../components/InputField/InputField';
import { useState } from 'react';

const FeedbackQuestion = ({ ques,onChange,vall,otherValue,setOtherValue,options}) => {
    
    const[toggle,setToggle]=useState(false)
 
    return (
        <div className='QuestionWrap'>
            {ques}
            <div className="formGroup profileRadioGroup mt-40">

            {
                   options.map((val,i)=>
                        <div className="radioBtnOuter" key={i}>
                        <input type="radio" id={`test${i}`} name="radio-group"  value={val}  checked={vall === val ? true : false} onChange={(e)=>onChange(e.target.value)}/>
                       
                        <label for={`test${i}`}>{val}</label>
                    </div>

                    )
                }
                
                <div className='otherInput'>
                    <div className="radioBtnOuter">
                        <input type="radio" id="test5" name="radio-group"  value={"Others"}  checked={vall === "Others" ? true : false} onChange={(e)=>onChange(e.target.value)} />
                        <label for="test5">Others</label>
                    </div>
                    <div className='otherWrap'>
                        <InputField placeholder={'Please Specify'} value={otherValue} onChange={(e)=>setOtherValue(e.target.value)} disabled ={vall !== "Others"?true:false}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeedbackQuestion