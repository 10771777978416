import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/Button/Button";
import InputField from "../../components/InputField/InputField";
import PageLoader from "../../components/PageLoader/PageLoader";
import ErrorToast from "../../components/Toast/ErrorToast";
import SuccessToast from "../../components/Toast/SuccessToast";
import { changeForgotPassword, clearState, userSelector } from "../../reducer/userSlice";

const ConfirmPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  //Toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isFetching, isSuccess, isError, errorMessage } =
    useSelector(userSelector);

  const handleSubmit = () => {
    if (password.length < 6) {
      setIsShowError(true);
      setErrMessage("Password too short!");
      return;
    }
    if (!password || !confirmPassword) {
      setIsShowError(true);
      setErrMessage("Please fill both fields");
      return;
    }
    if (password !== confirmPassword) {
      setIsShowError(true);
      setErrMessage("Passwords do not match!");
    }
    else {
      let goodToken = `getPreppedMentee ${token}`
      const obj = {
        token: goodToken,
        password
      }
      dispatch(changeForgotPassword(obj));
    }
  };

  const handlePressSubmit = (e) => {
    if (e.key === "Enter") {
      if (password.length < 6) {
        setIsShowError(true);
        setErrMessage("Password too short!");
        return;
      }
      if (!password || !confirmPassword) {
        setIsShowError(true);
        setErrMessage("Please fill both fields");
        return;
      }
      if (password !== confirmPassword) {
        setIsShowError(true);
        setErrMessage("Passwords do not match!");
      }
      else {
        let goodToken = `getPreppedAdmin ${token}`
        const obj = {
          token: goodToken,
          password
        }
        dispatch(changeForgotPassword(obj));
      }
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setIsShowSuccess(true);
      setSuccessMessage("Success!")
      setTimeout(() => {
        navigate("/", { replace: true });
      }, 2000)
      dispatch(clearState());
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      setIsShowError(true);
      setErrMessage(errorMessage);
      dispatch(clearState());
    }
  }, [isError])

  return (
    <>
      {isFetching && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <SuccessToast
        show={isShowSuccess}
        setShow={() => setIsShowSuccess(false)}
        message={successMessage}
      />
      <div className="autheticationWrapper">
        <div className="autheticationBox">
          <div className="loginLeft">
            <div className="componentConfirmPassword">
              <div className="formWrapper">
                <div className="BlueTag mb-10">Registered Account</div>
                <h2 className="heading-2 mb-20">Forgot Password</h2>
                <InputField
                  type={"password"}
                  className={"FormInput mb-20"}
                  placeholder={"Enter your new password"}
                  onKeyPress={handlePressSubmit}
                  id={"CreatePass"}
                  labelName={"Create new Password"}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <InputField
                  type={"password"}
                  className={"FormInput"}
                  placeholder={"Enter the same password"}
                  onKeyPress={handlePressSubmit}
                  id={"confirmPass"}
                  labelName={"Confirm Password*"}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                />
              </div>

              <div className="buttonWrapper mt-30">
                <Button
                  className={"blackButton"}
                  name={"Confirm"}
                  onClick={handleSubmit}
                />
              </div>

              <div className="policyWrapper mt-20">
                <p className="policyText">Copyright @getprepped</p>|
                <p className="policyText">Privacy Policy</p>
              </div>
            </div>
          </div>
          <div className="loginRight">
            <img src="/assets/img/LoginBG.png" alt="" />
          </div>
        </div>
      </div>
    </>

  );
};

export default ConfirmPassword;
