import { BrowserRouter, Route, Routes,useLocation } from "react-router-dom";
import "./App.css";
import Header from "./components/header/Header";
import Menu from "./components/menu/Menu";
import Authentication from "./pages/authentication/Authentication";
import { PrivateRoute } from "./helpers/PrivateRoute";
import MentorLogin from "./pages/authentication/MentorLogin";
import MentorsProfile from "./pages/Mentor/mentorsProfile/MentorsProfile";
import EditProfile from "./pages/Mentor/editProfile/EditProfile";
import CallsBooking from "./pages/Mentor/callsBooking/CallsBooking";
import MentorFeedback from "./pages/Mentor/mentorFeedback/MentorFeedback";
import ViewFeedback from "./pages/Mentor/mentorFeedback/ViewFeedback";
import MentorSlots from "./pages/Mentor/mentorSlots/MentorSlots";
import EditSlots from "./pages/Mentor/mentorSlots/EditSlot";
import { useSelector } from "react-redux";
import { userSelector } from "./reducer/userSlice";
import CreateFeedback from "./pages/Mentor/createFeedback/CreateFeedback";
import { useState } from "react";
import ConfirmPassword from "./pages/authentication/ConfirmPassword";

function App() {

  const [isMenu, setIsMenu] = useState(false)
  const { token } = useSelector(userSelector);
  
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <>
      <Header setIsMenu={setIsMenu} isMenu={isMenu} />
      <div className="appContainer">

          {((pathname !== "/authentication" && pathname !== "/createFeedback") && (token || pathname === "/")) && <Menu isMenu={isMenu} setIsMenu={setIsMenu}/>}
          <div className={`routesContainer ${(pathname === "/authentication" || pathname === "/createFeedback") || !token ? "LoginWrapper" : ""}`} >
            <Routes>
              <Route path="/authentication" exact element={<Authentication />} />
              <Route path="/changePassword/:token" exact element={<ConfirmPassword />} />
              <Route path="/" exact element={<PrivateRoute />} >
              
                <Route path="/" exact element={<CallsBooking />} />
                <Route path="/mentorsProfile" exact element={<MentorsProfile />} />
                <Route path="/editProfile" exact element={<EditProfile />} />
                <Route path="/mentorFeedback" exact element={<MentorFeedback />} />
                <Route path="/viewFeedback/:id" exact element={<ViewFeedback />} />
                <Route path="/mentorSlots" exact element={<MentorSlots />} />
                <Route path="/editSlots" exact element={<EditSlots />} />
                <Route path="/createFeedback" exact element={<CreateFeedback />} />
                {/* <Route path="/callDetails" exact element={<CallDetails />} /> */}

              </Route>

            </Routes>
          </div>
      </div>
    </>
  );
}

export default App;
