import { configureStore } from "@reduxjs/toolkit";
import { interviewSlice } from "../interviewSlice";
import { mentorSlice } from "../mentorSlice";
import { userSlice } from "../userSlice";

export default configureStore({
  reducer: {
    mentorData: mentorSlice.reducer,
    user:userSlice.reducer,
    interview:interviewSlice.reducer,
  },
});
