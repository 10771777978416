import React, { useState } from "react";
import "./EditProfileStyle.css";
import Button from "../../../components/Button/Button";
import InputField from "../../../components/InputField/InputField";
import { useDispatch, useSelector } from "react-redux";
import {
  clearState,
  getMentorDetails,
  mentorSliceData,
  saveMentorDetails,
} from "../../../reducer/mentorSlice";
import PageLoader from "../../../components/PageLoader/PageLoader";
import { useEffect } from "react";
import UploadImagePopup from "../../../components/uploadImgPopup/UploadImagePopup";
import * as filestack from "filestack-js";
import { useNavigate } from "react-router-dom";
import ErrorToast from "../../../components/Toast/ErrorToast";

const EditProfile = () => {
  const { mentorDetails, isFetching, isSuccess, isError, errorMessage } = useSelector(mentorSliceData);
  const [email, setEmail] = useState("");
  const [linkedInLink, setLinkedInLink] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [designation, setDesignation] = useState("");
  const [experience, setExperience] = useState("");
  const [interestArea, setInterestArea] = useState([]);
  const [topic, setTopic] = useState([]);
  const [selectedProfileKey, setSelectedProfileKey] = useState("");
  const [urlProfile, setUrlProfile] = useState(null);
  const [url, setUrl] = useState("");
  const [uploadPopUp, setUploadPopUp] = useState(false);
  const [load, setLoad] = useState(false);
  const [active, setActive] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [isShowError, setIsShowError] = useState(false);
  const client = filestack.init("AcpQ2RjKSnWT5GjS9f6wOz");
  const token = {};
  
  const areasOfInterest = [
    "Taxation",
    "CA/CMA/CFA",
    "CAT/MBA",
    "Financial Advisory",
    "Investment Banking",
    "Auditing",
    "FP&A",
    "Financial Reporting",
    "Mergers & Acquisitions",
    "Accounting",
    "Financial Analysis",
    "Risk Management",
    "Asset Management",
    "Valuations"
  ];

  const mentorTopic = [
    "Career Advice",
    "Job Search",
    "Skill Development",
    "Competitive Exams",
    "Career Transition"
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getMentorDetails());
  }, []);

  const handleUploadProfile = (event) => {
    if (
      event.target.files.length > 0
    ) {
      const files = event.target.files[0];
      setLoad(true);
      client
        .upload(files, {}, token)
        .then((res) => {
          if (res) {
            setSelectedProfileKey(res.name);
            setUrl(res.url);
            setLoad(false);
            setActive(true);
          } else {
            setLoad(false);
          }
        })
        .catch((err) => {
          setLoad(false);
          setIsShowError(true);
          setErrMessage("Something went wrong!");
        });
    }
  };

  const clearProfile = () => {
    setUrl(null);
    setSelectedProfileKey(null);
  };
  const clearRemoveProfile = () => {
    setUploadPopUp(false);
    setUrlProfile(null);
  };

  useEffect(() => {
    if (mentorDetails !== null) {
      setEmail(mentorDetails?.email);
      setLinkedInLink(mentorDetails?.linkedin);
      setContactNumber(mentorDetails?.mobileNumber);

      mentorDetails?.GetPrepInterests?.map((value) => {
        if (interestArea?.length === 0) {
          setInterestArea((prev) => [...prev, value.interest]);
        }
      });

      mentorDetails?.GetPrepTopics?.map((value) => {
        if (topic?.length === 0) {
          setTopic((prev) => [...prev, value.Topic]);
        }
      });
      if (mentorDetails?.profileImg) {
        setUrlProfile(mentorDetails?.profileImg);
      }
      setCompanyName(mentorDetails?.GetPrepProfession?.company);
      setDesignation(mentorDetails?.GetPrepProfession?.designation);
      setExperience(mentorDetails?.GetPrepProfession?.yrsOfExp);
    }
  }, [mentorDetails]);

  const onChangeKeyAreas = (e) => {
    const { value } = e.target;
    if (interestArea.includes(value))
      setInterestArea(interestArea.filter((item) => item !== value));
    else setInterestArea((val) => [...val, value]);
  };

  const onChangeTopic = (e) => {
    const { value } = e.target;
    if (topic.includes(value)) setTopic(topic.filter((item) => item !== value));
    else setTopic((val) => [...val, value]);
  };

  const saveMentorDetail = () => {
    const obj = {
      linkedin: linkedInLink,
      mobileNumber: contactNumber,
      companyName: companyName,
      designation: designation,
      experience: experience,
      PrepInterests: interestArea,
      topic: topic,
      profileImg: urlProfile,
    };
    var expr = /^[6-9]\d{9}$/;
    if (expr.test(contactNumber)) {
      dispatch(saveMentorDetails(obj));
    } else {
      setIsShowError(true);
      setErrMessage("Enter valid mobile number");
    }
  };
  useEffect(() => {
    if (isSuccess) {
      navigate("/mentorsProfile");
      localStorage.setItem("imgprofile", urlProfile);
      dispatch(clearState());
    }
    if (isError) {
      setIsShowError(true);
      setErrMessage(errorMessage);
      dispatch(clearState());
    }
  }, [isSuccess, isError]);

  return (
    <>
      {(isFetching || load) && <PageLoader />}
      <div className="editWrapper">
        <div className="profileHeader withbutton">
          <ErrorToast
            show={isShowError}
            setShow={() => setIsShowError(false)}
            message={errMessage}
          />
          <UploadImagePopup
            active={active}
            popout={uploadPopUp}
            handleClose={() => {
              setUploadPopUp(false);
              setSelectedProfileKey("");
              setActive(false);
            }}
            handleOk={() => {
              setUploadPopUp(false);
              setSelectedProfileKey("");
              if (active) {

                setUrlProfile(url);
              }
              else {
                setIsShowError(true);
                setErrMessage("Please upload image");
                setUploadPopUp(true);
              }
            }}
            handleUploadProfile={handleUploadProfile}
            value={selectedProfileKey}
            handleRemove={clearProfile}
            handleDelete={() => {
              if (selectedProfileKey !== "") {
                setActive(false);
              } else {
                setActive(true);
              }
            }}
            clearRemoveProfile={clearRemoveProfile}
            // accept={"jpg"}
          />
          <h2 className="heading-3">My Profile</h2>
          <Button
            className={"blueButton"}
            name={"Save Changes"}
            onClick={() => {
              saveMentorDetail();
            }}
          />
        </div>

        <div className="editProfileWrapper">
          <div className="mentorProfile profileMentor">
            <div className="profileImageWrap">
              <img
                src={
                  urlProfile === null
                    ? "https://events.powerathletehq.com/wp-content/uploads/2018/06/grey-person-coming-soon-e1529779699986.jpg"
                    : urlProfile
                }
                alt=""
                className="mentorImg"
              />
              <img
                src="/assets/icons/editProfile.png"
                className="editIconProfile"
                onClick={() => setUploadPopUp(true)}
              />
            </div>

            <div className="mentorDetails">
              <p className="mentorName">{mentorDetails?.firstName}</p>
              <p className="designation">
                {mentorDetails?.GetPrepProfession?.designation ? `${mentorDetails?.GetPrepProfession?.designation}, ${mentorDetails?.GetPrepProfession?.company}` : "---"}
              </p>
            </div>
          </div>

          <div className="profileDetails mt-40">
            {/* <InputField
              type={"email"}
              className={"FormInput mb-30"}
              placeholder={"user@gmail.com"}
              id={"name"}
              labelName={"Email"}
              disabled={true}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            /> */}

            <InputField
              type={"text"}
              className={"FormInput mb-30"}
              placeholder={"linkedin.com/user"}
              id={"linkedinProfile"}
              labelName={"LinkedIn Profile"}
              value={linkedInLink}
              onChange={(e) => setLinkedInLink(e.target.value)}
            />

            <InputField
              type={"email"}
              className={"FormInput"}
              placeholder={"9876543210"}
              id={"name"}
              labelName={"Contact Number"}
              value={contactNumber}
              onChange={(e) => {if(e.target.value.length > 10) return; setContactNumber(e.target.value)}}
          />
            <small className="smallNotification mt-10 mb-30">
              Contact number won’t be visible on your public profile
            </small>

            <div className="formGroup profileRadioGroup">
              <label htmlFor="radio-group" className="formLabel">
                Key areas of professional interest or Domain of expertise*
              </label>
              <div className="feedbackRadioGroup">
                {areasOfInterest?.map((values, i) => (
                  <div className="radioBtnOuter" key={i}>
                    <input
                      type="checkbox"
                      id={`test${values}`}
                      name="radio-group"
                      value={values}
                      checked={interestArea.includes(values)}
                      onChange={onChangeKeyAreas}
                    />
                    <label htmlFor={`test${values}`}>{values}</label>
                  </div>
                ))}
              </div>
            </div>

            <div className="formGroup profileRadioGroup">
              <label htmlFor="radio-group" className="formLabel">
                Mentorship topic
              </label>
              <div className="feedbackRadioGroup">
                {mentorTopic?.map((values, i) => (
                  <div className="radioBtnOuter" key={i}>
                    <input
                      type="checkbox"
                      id={`test${values}`}
                      name="radio-group"
                      value={values}
                      checked={topic.includes(values)}
                      onChange={onChangeTopic}
                    />
                    <label htmlFor={`test${values}`}>{values}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className="editProfileDetailNew">
              <InputField
                type={"text"}
                className={"FormInput mb-30"}
                placeholder={"Enter Company name"}
                id={"companyName"}
                labelName={"Company"}
                value={companyName}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
              />

              <InputField
                type={"text"}
                className={"FormInput mb-30"}
                placeholder={"Enter Designation"}
                id={"Designation"}
                labelName={"Designation"}
                value={designation}
                onChange={(e) => setDesignation(e.target.value)}
              />

              <InputField
                type={"email"}
                className={"FormInput"}
                placeholder={"Years of Experience"}
                id={"name"}
                labelName={"Years of Experience"}
                value={experience}
                onChange={(e) => setExperience(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
