import moment from 'moment/moment';
import React, { useEffect, useState } from 'react'
import Button from '../../../components/Button/Button'
import TimePicker from "rc-time-picker-date-fns";
import "rc-time-picker-date-fns/assets/index.css";
import { addAdminAvailableSlots, clearState, mentorSliceData } from '../../../reducer/mentorSlice';
import { useDispatch, useSelector } from 'react-redux';
import PageLoader from '../../../components/PageLoader/PageLoader';
import { useNavigate } from 'react-router-dom';
import ErrorToast from "../../../components/Toast/ErrorToast";

const EditSlot = () => {

    const [isShowError, setIsShowError] = useState(false);
    const [errMessage, setErrMessage] = useState("");

    const [activeRow, setActiveRow] = useState();
    const [selectedWeekDays, setSelectedWeekDays] = useState([]);
    const [dateRange, setDateRange] = useState({
        from: "",
        to: ""
    })

    const { isSuccess, isFetching } =
        useSelector(mentorSliceData);

    const navigate = useNavigate();

    const now = new Date(moment().hour(0).minute(0).second((0)));
    const format = "h:mm a";
    const daysInAWeek = ["sun", "mon", "tue", "wed", "thur", "fri", "sat"];
    const dispatch = useDispatch();

    const [timeRangeCount, setTimeRangeCount] = useState({
        mon: [0],
        tue: [0],
        wed: [0],
        thur: [0],
        fri: [0],
        sat: [0],
        sun: [0],
    });
    const [interviewTime, setInterviewTime] = useState({
        sun: [{ from: now, to: now }],
        mon: [{ from: now, to: now }],
        tue: [{ from: now, to: now }],
        wed: [{ from: now, to: now }],
        thur: [{ from: now, to: now }],
        fri: [{ from: now, to: now }],
        sat: [{ from: now, to: now }]
    });

    const handleCheckboxToggle = (day) => {
        if (selectedWeekDays.includes(day)) setSelectedWeekDays(prev => prev.filter(item => item !== day))
        else setSelectedWeekDays(prev => [...prev, day]);
    };

    const handleTimeFromChange = (e, type, i) => {
        const value = e;
        const timeSlot = interviewTime[type];
        timeSlot[i].from = value;
        setInterviewTime((prev) => ({ ...prev, [type]: [...timeSlot] }));
    };

    const handleTimeToChange = (e, type, i) => {
        const value = e;
        const timeSlot = interviewTime[type];
        timeSlot[i].to = value;
        setInterviewTime((prev) => ({ ...prev, [type]: [...timeSlot] }));
    };

    const handleTimeSlotRemove = (type, index) => {
        if (interviewTime[type].length === 1) {
            setSelectedWeekDays(prev => prev.filter(day => day !== type));
        }
        else {
            const timeSlots = interviewTime[type];
            const timeSlot = timeRangeCount[type];
            timeSlot.splice(index, 1);
            timeSlots.splice(index, 1);
            setTimeRangeCount((prev) => ({ ...prev, [type]: [...timeSlot] }));
            setInterviewTime((prev) => ({ ...prev, [type]: [...timeSlots] }));
        }
    };

    const validate = () => {
        if (dateRange.from === "" || dateRange.to === "") return "date";
        if (selectedWeekDays.length === 0) return "noSlot";
        let check = "";
        selectedWeekDays.forEach((day) => {
            if(day === "sun") return null;
            interviewTime[day].forEach((time) => {
                if ((new Date(time.from).getTime() + 1200000) > new Date(time.to).getTime()) {
                    check = "time";
                };
            })
        })
        return check;
    };

    const handleSave = () => {
        if (validate()) {
            if (validate() === "date") {
                setIsShowError(true);
                setErrMessage("Please select a date range!");
            }
            if (validate() === "time") {
                setIsShowError(true);
                setErrMessage("Time slots should have atleast 20 min difference!")
            }
            if (validate() === "noSlot") {
                setIsShowError(true);
                setErrMessage("Please select a day for availablity!")
            }
        }
        else {
            let errorCheck = [];
            let obj;
            obj = interviewTime;
            for (const day in interviewTime) {
                if (!selectedWeekDays.includes(day)) {
                    obj = { ...obj, [day]: [] }
                }
                else {
                    const din = obj[day];
                    din.map((time) => {
                        if( +moment(time.from).format("HH:mm").slice(0,2) < 10 || +moment(time.from).format("HH:mm").slice(0,2) > 21) {
                            setIsShowError(true);
                            setErrMessage("Please select time between 10am - 10pm for " + day);
                            errorCheck.push("error");
                            return null;
                        }
                        if(errorCheck.length === 0) {
                            time.from = moment(time.from).format("HH:mm:ss");
                            time.to = moment(time.to).format("HH:mm:ss")
                        }
                    });

                    obj = { ...obj, [day]: [...din] }
                }
            }
            const object = {
                mentorId: localStorage.getItem("mentorId"),
                dateRange: dateRange,
                interviewTime: interviewTime
            }
            console.log(errorCheck)
            if(errorCheck.length === 0) {
                dispatch(addAdminAvailableSlots(object));
                setTimeRangeCount({
                    mon: [0],
                    tue: [0],
                    wed: [0],
                    thur: [0],
                    fri: [0],
                    sat: [0],
                    sun: [0],
                });
                setInterviewTime({
                    sun: [{ from: now, to: now }],
                    mon: [{ from: now, to: now }],
                    tue: [{ from: now, to: now }],
                    wed: [{ from: now, to: now }],
                    thur: [{ from: now, to: now }],
                    fri: [{ from: now, to: now }],
                    sat: [{ from: now, to: now }]
                });
            }
        }

    }

    useEffect(() => {
        const InBetweenDays = Math.abs((new Date(dateRange.from).getTime() - new Date(dateRange.to).getTime()) / (1000 * 60 * 60 * 24));
        if (dateRange.to !== "") {
            if (InBetweenDays <= 6) {
                setSelectedWeekDays([]);
                for (let i = 0; i <= InBetweenDays; i++) {
                    if (i > 6) break;
                    const day = new Date(moment(dateRange.from).add(i, "day")).getDay();
                    setSelectedWeekDays(prev => [...prev, daysInAWeek[day]]);
                }
            }
            else {
                setSelectedWeekDays(daysInAWeek);
            }
        }
    }, [dateRange]);

    useEffect(() => {
        if (isSuccess) {
            navigate("/mentorSlots");
            dispatch(clearState());
        }
    }, [isSuccess])

    return (
        <>
            {isFetching && <PageLoader />}
            <ErrorToast
            show={isShowError}
            setShow={() => setIsShowError(false)}
            message={errMessage}
          />
            <div className='editSlotWrap'>

                <div className="profileHeader">
                    <h2 className="heading-3">Add your daily hours </h2>
                    <Button className={'blueButton'} onClick={handleSave} name={'Save Changes'} />
                </div>
                <div className='editSlotsWrap'>

                    <div className="dateSlotTop">
                        <p className="Slotheading">Select Date Range</p>
                        <div className='dateWrapSlots'>
                            <input type={"date"} min={moment(new Date()).format("YYYY-MM-DD")} value={dateRange.from} onChange={(e) => setDateRange({ from: e.target.value, to: "" })} onKeyDown={(e) => e.preventDefault()} />
                            <p className="dateRangeTo">to</p>
                            <input type={"date"} min={dateRange.from && moment(dateRange.from).format("YYYY-MM-DD")} max={moment().add(1,"month").format("YYYY-MM-DD")} value={dateRange.to} onChange={(e) => setDateRange(prev => ({ ...prev, to: e.target.value }))} onKeyDown={(e) => e.preventDefault()} />
                        </div>
                    </div>

                    <div className='weeklyTableWrapNew'>
                        <table className="weeklyTable slotTable" cellSpacing={0} cellPadding={0}>
                            <tbody>
                                {daysInAWeek.map((day, index) => (
                                    <tr className={activeRow === index && "activeBlue"} onClick={() => setActiveRow(index)}>
                                        <td>
                                            {day !== "sun" &&<div className="togglerContainer">
                                                <div className={`toggler ${(selectedWeekDays.includes(day) && day !== "sun") ? 'active' : ''}`} onClick={() => handleCheckboxToggle(day)}>
                                                    <div className="togglerBtn"></div>
                                                </div>
                                            </div>}
                                        </td>
                                        <td>{day.toUpperCase()}</td>
                                        <td>
                                            {(selectedWeekDays.includes(day) && day !== "sun")?
                                                <div className="timeSlots">
                                                    {timeRangeCount[day]?.map((j, i) => (
                                                        <div className="timeSlot">
                                                            <div className="">
                                                                <TimePicker
                                                                    showSecond={false}
                                                                    defaultValue={now}
                                                                    value={interviewTime[day][i]?.from}
                                                                    className="xxx"
                                                                    onChange={(e) =>
                                                                        handleTimeFromChange(e, day, i)
                                                                    }
                                                                    format={format}
                                                                    use12Hours
                                                                    minuteStep={10}
                                                                />
                                                            </div>
                                                            <svg
                                                                width="16"
                                                                height="16"
                                                                viewBox="0 0 16 16"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M2.83325 8C2.83325 7.72386 3.05711 7.5 3.33325 7.5H12.6666C12.9427 7.5 13.1666 7.72386 13.1666 8C13.1666 8.27614 12.9427 8.5 12.6666 8.5H3.33325C3.05711 8.5 2.83325 8.27614 2.83325 8Z"
                                                                    fill="#404040"
                                                                />
                                                            </svg>
                                                            <div className="">
                                                                <TimePicker
                                                                    showSecond={false}
                                                                    //   defaultValue={now}
                                                                    value={interviewTime[day][i]?.to}
                                                                    className="xxx"
                                                                    onChange={(e) =>
                                                                        handleTimeToChange(e, day, i)
                                                                    }
                                                                    format={format}
                                                                    use12Hours
                                                                    minuteStep={10}
                                                                />
                                                            </div>
                                                            <div style={{ marginLeft: "22px" }} className="pointer" onClick={(e) => handleTimeSlotRemove(day, i)}>
                                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M15.75 4.48499C13.2525 4.23749 10.74 4.10999 8.235 4.10999C6.75 4.10999 5.265 4.18499 3.78 4.33499L2.25 4.48499" stroke="#EF5350" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M6.375 3.7275L6.54 2.745C6.66 2.0325 6.75 1.5 8.0175 1.5H9.9825C11.25 1.5 11.3475 2.0625 11.46 2.7525L11.625 3.7275" stroke="#EF5350" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M14.1373 6.85501L13.6498 14.4075C13.5673 15.585 13.4998 16.5 11.4073 16.5H6.5923C4.4998 16.5 4.4323 15.585 4.3498 14.4075L3.8623 6.85501" stroke="#EF5350" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M7.74707 12.375H10.2446" stroke="#EF5350" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M7.125 9.375H10.875" stroke="#EF5350" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    ))}


                                                </div>
                                                :
                                                "Unavailable"
                                            }
                                        </td>
                                        <td>
                                            {(selectedWeekDays.includes(day) && day !== "sun") && <div className="pointer" onClick={() => {
                                                setTimeRangeCount({
                                                    ...timeRangeCount,
                                                    [day]: [...timeRangeCount[day], 0],
                                                });
                                                setInterviewTime({
                                                    ...interviewTime,
                                                    [day]: [
                                                        ...interviewTime[day],
                                                        { from: now, to: now },
                                                    ],
                                                });
                                            }}>
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.6665 10H13.3332" stroke="#3397EF" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M10 13.3332V6.6665" stroke="#3397EF" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M7.49984 18.3332H12.4998C16.6665 18.3332 18.3332 16.6665 18.3332 12.4998V7.49984C18.3332 3.33317 16.6665 1.6665 12.4998 1.6665H7.49984C3.33317 1.6665 1.6665 3.33317 1.6665 7.49984V12.4998C1.6665 16.6665 3.33317 18.3332 7.49984 18.3332Z" stroke="#3397EF" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>

                                            </div>}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </>
    )
}

export default EditSlot