import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";

let orgId;
let token;
let adminId;
let mentorId;

let setTokenValues = () => {
    orgId = localStorage.getItem("orgId");
    token = localStorage.getItem("token");
    adminId = localStorage.getItem("adminId");
    mentorId = localStorage.getItem("mentorId")
};

// Mentor
// All Get Api
export const getMentorDetails = createAsyncThunk("mentor/getMentorDetails", async (thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(environment.baseURL +
            "/api/getPrepped/admin/" +
            orgId +
            "/mentor/" +
            mentorId + "/getMentorDetails",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        if (response.statusText === 'Unauthorized') {
            localStorage.clear();
            window.location.reload();
        }
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);

export const getAllUpcomingFeedback = createAsyncThunk("mentor/getAllUpcomingCalls", async (thunkAPI) => {
    try {
        setTokenValues();

        // /:organizationId/mentor/:mentorId/getUpcomingInterviewDetails

        const response = await fetch(environment.baseURL +
            "/api/getPrepped/admin/" +
            orgId +
            "/mentor/" +
            mentorId + "/getUpcomingInterviewDetails",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        if (response.statusText === 'Unauthorized') {
            localStorage.clear();
            window.location.reload();
        }
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);
export const getAllUpcomingCalls = createAsyncThunk("mentor/getAllUpcomingCalls", async (thunkAPI) => {
    try {
        setTokenValues();

        // "/:organizationId/mentor/:mentorId/getPastInterviewDetails"
        const response = await fetch(environment.baseURL +
            "/api/getPrepped/admin/" +
            orgId +
            "/mentor/" +
            mentorId + "/getUpcomingInterviewDetails",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        if (response.statusText === 'Unauthorized') {
            localStorage.clear();
            window.location.reload();
        }
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);

export const getAllPastCalls = createAsyncThunk("mentor/getAllPastCalls", async (thunkAPI) => {
    try {
        setTokenValues();

        // "/:organizationId/mentor/:mentorId/getPastInterviewDetails"
        const response = await fetch(environment.baseURL +
            "/api/getPrepped/admin/" +
            orgId +
            "/mentor/" +
            mentorId + "/getPastInterviewDetails",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        if (response.statusText === 'Unauthorized') {
            localStorage.clear();
            window.location.reload();
        }
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);

export const getAllMenteeFeedback = createAsyncThunk("mentor/AllMenteeFeedback", async (thunkAPI) => {
    try {
        setTokenValues();



        const response = await fetch(environment.baseURL +
            "/api/getPrepped/admin/" +
            orgId +
            "/mentor/" +
            mentorId + "/getUpcomingInterviewDetails",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        if (response.statusText === 'Unauthorized') {
            localStorage.clear();
            window.location.reload();
        }
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);


export const getSpecficMenteeFeedback = createAsyncThunk("mentor/UpcomingFeedback", async (thunkAPI) => {
    try {
        setTokenValues();

        // /:organizationId/mentor/:mentorId/getUpcomingInterviewDetails

        const response = await fetch(environment.baseURL +
            "/api/getPrepped/admin/" +
            orgId +
            "/mentor/" +
            mentorId + "/getUpcomingInterviewDetails",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        if (response.statusText === 'Unauthorized') {
            localStorage.clear();
            window.location.reload();
        }
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);


export const getSpecficMentorFeedback = createAsyncThunk("mentor/getspecficMentorFeedback ", async (thunkAPI) => {
    try {
        setTokenValues();

        // "/:organizationId/mentor/:mentorId/getPastInterviewDetails"
        const response = await fetch(environment.baseURL +
            "/api/getPrepped/admin/" +
            orgId +
            "/mentor/" +
            mentorId + "/getPastInterviewDetails",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        if (response.statusText === 'Unauthorized') {
            localStorage.clear();
            window.location.reload();
        }
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);

export const getSlots = createAsyncThunk("mentor/getMentorInterviewSlots", async (obj, thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(environment.baseURL +
            "/api/getPrepped/admin/" +
            orgId +
            "/mentor/" +
            mentorId + `/getMentorInterviewSlots?date=${obj.date}`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        if (response.statusText === 'Unauthorized') {
            localStorage.clear();
            window.location.reload();
        }
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);

export const getFeedbackAnswers = createAsyncThunk(
    "mentor/getFeedbackAnswers",
    async (id, thunkAPI) => {
        try {
            setTokenValues();
            const response = await fetch(
                environment.baseURL +
                "/api/getPrepped/admin/booking/" +
                id +
                "/getFeedbackAnswers",
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token,
                    }

                });
            if (response.statusText === 'Unauthorized') {
                localStorage.clear();
                window.location.reload();
            }
            let data = await response.json();
            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

// post Api

// export const editProfileMentor= createAsyncThunk(
//     "addEventPhotoStu",
//     async (obj, thunkAPI) => {
//       try {
//         setTokenValues();
//         const response = await fetch(
//           environment.baseURL +
//             "/api/org/" +
//             orgId +
//             "/admin/" +
//             adminId +
//             "/event/" +
//             obj.eventId +
//             `/addEventPhotoAdmin`,

//           {
//             method: "POST",
//             headers: {
//               Accept: "application/json",
//               "Content-Type": "application/json",
//               Authorization: token,
//             },
//             body: JSON.stringify(obj),
//           }
//         );
//         let data = await response.json(obj);
//         if (response.status === 200) {
//           return data.Data;
//         } else {
//           return thunkAPI.rejectWithValue(data);
//         }
//       } catch (error) {
//         return thunkAPI.rejectWithValue({ error: error.message });
//       }
//     }
//   );

// /:mentorId/booking/:bookingId/saveMentorFeedbackAnswers
export const saveMentorFeedbackAnswers = createAsyncThunk(
    "saveMentorFeedbackAnswers",
    async ({ payload, bookingId }, thunkAPI) => {
        try {
            setTokenValues();
            const response = await fetch(
                environment.baseURL +
                "/api/getPrepped/admin/" +

                mentorId + "/booking/" + bookingId + "/saveMentorFeedbackAnswers",

                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                    body: JSON.stringify(payload),
                }
            );
            if (response.statusText === 'Unauthorized') {
                localStorage.clear();
                window.location.reload();
            }
            let data = await response.json();
            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

// mentor
export const addAdminAvailableSlots = createAsyncThunk("mentor/addAdminAvailableSlots", async (obj, thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(environment.baseURL +
            "/api/getPrepped/admin/" +
            orgId +
            "/mentor/" +
            mentorId + "/addAdminAvailableSlots",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify(obj)
            }
        );
        if (response.statusText === 'Unauthorized') {
            localStorage.clear();
            window.location.reload();
        }
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);

// /:mentorId/saveMentorDetails
export const saveMentorDetails = createAsyncThunk("mentor/saveMentorDetails", async (obj, thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(environment.baseURL +
            "/api/getPrepped/admin/" +
            mentorId + "/saveMentorDetails",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify(obj)
            }
        );
        if (response.statusText === 'Unauthorized') {
            localStorage.clear();
            window.location.reload();
        }
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);

export const changePassword = createAsyncThunk(
    "mentor/changePassword",
    async ({newPassword, oldPassword}, thunkAPI) => {
      try {
        setTokenValues();
        const obj = {
            oldPassword:oldPassword,
            password: newPassword
        }
        const response = await fetch(`${environment.baseURL}/api/getPrepped/Admin/changePassword`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: token
            },
            body: JSON.stringify(obj),
          }
        );
        let data = await response.json();
        if (response.status === 200) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e) {
        thunkAPI.rejectWithValue(e);
      }
    }
  );

  export const updateMentorJoiningStatus = createAsyncThunk("mentor/updateMentorJoiningStatus", async ({status, meetingId}, thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(environment.baseURL +
            "/api/getPrepped/admin/meeting/" +
            meetingId + "/updateMentorJoiningStatus",
            {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify({status: status})
            }
        );
        if (response.statusText === 'Unauthorized') {
            localStorage.clear();
            window.location.reload();
        }
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);  



export const mentorSlice = createSlice({
    name: "mentorData",
    initialState: {
        isFetching: false,
        isSuccess: false,
        isError: false,
        errorMessage: "",
        allMentors: [],
        mentorDetail: [],
        allUpcomingCalls: [],
        allPastCalls: [],
        mentorDetails: [],
        specficMenteeFeedback: [],
        specficMentorFeedback: [],
        slots: [],
        allMenteeFeedback: [],
        feedbackAnswers: [],
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isFetching = false;
            return state;
        },
    },
    extraReducers: {
        [getSpecficMenteeFeedback.pending]: (state) => {
            state.isFetching = true;
        },
        [getSpecficMenteeFeedback.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.specficMenteeFeedback = payload;
            return state;
        },
        [getSpecficMenteeFeedback.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },

        [getAllUpcomingFeedback.pending]: (state) => {
            state.isFetching = true;
        },
        [getAllUpcomingFeedback.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.allUpcomingCalls = payload.Data;
            return state;
        },
        [getAllUpcomingFeedback.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getSpecficMentorFeedback.pending]: (state) => {
            state.isFetching = true;
        },
        [getSpecficMentorFeedback.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.specficMentorFeedback = payload;
            return state;
        },
        [getSpecficMentorFeedback.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getAllUpcomingCalls.pending]: (state) => {
            state.isFetching = true;
        },
        [getAllUpcomingCalls.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.allUpcomingCalls = payload.Data;
            return state;
        },
        [getAllUpcomingCalls.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getAllPastCalls.pending]: (state) => {
            state.isFetching = true;
        },
        [getAllPastCalls.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.allPastCalls = payload.Data;
            return state;
        },
        [getAllPastCalls.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getMentorDetails.pending]: (state) => {
            state.isFetching = true;
        },
        [getMentorDetails.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.mentorDetails = payload.Data;
            return state;
        },
        [getMentorDetails.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getSlots.pending]: (state) => {
            state.isFetching = true;
        },
        [getSlots.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.slots = payload.Data;
            return state;
        },
        [getSlots.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },

        [saveMentorDetails.pending]: (state) => {
            state.isFetching = true;
        },
        [saveMentorDetails.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.isSuccess = true;
            // state.slots = payload;
            return state;
        },
        [saveMentorDetails.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },

        [addAdminAvailableSlots.pending]: (state) => {
            state.isFetching = true;
        },
        [addAdminAvailableSlots.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.isSuccess = true;
            // state.slots = payload;
            return state;
        },
        [addAdminAvailableSlots.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },

        [getAllMenteeFeedback.pending]: (state) => {
            state.isFetching = true;
        },
        [getAllMenteeFeedback.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.allMenteeFeedback = payload;
            return state;
        },
        [getAllMenteeFeedback.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getFeedbackAnswers.pending]: (state) => {
            state.isFetching = true;
        },
        [getFeedbackAnswers.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.feedbackAnswers = payload;
            return state;
        },
        [getFeedbackAnswers.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [saveMentorFeedbackAnswers.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [saveMentorFeedbackAnswers.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [saveMentorFeedbackAnswers.pending]: (state) => {
            state.isFetching = true;
        },
        [changePassword.fulfilled]: (state) => {
            state.isFetching = false;
            state.isSuccess = true;
            return state;
          },
          [changePassword.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
          },
          [changePassword.pending]: (state) => {
            state.isFetching = true;
          },
          [updateMentorJoiningStatus.fulfilled]: (state) => {
            state.isFetching = false;
            state.isSuccess = true;
            return state;
          },
          [updateMentorJoiningStatus.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
          },
          [updateMentorJoiningStatus.pending]: (state) => {
            state.isFetching = true;
          },
    }
});

export const { clearState } = mentorSlice.actions;

export const mentorSliceData = (state) => state.mentorData;