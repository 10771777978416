import React, { useEffect } from "react";
import "./MentorsProfileStyle.css";
import Button from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ChangePassPopup from '../../../components/changePassPopup/ChangePassPopup';
import {
  changePassword,
  clearState,
  getMentorDetails,
  mentorSliceData,
} from "../../../reducer/mentorSlice";
import PageLoader from "../../../components/PageLoader/PageLoader";
import { useState } from "react";
import ErrorToast from "../../../components/Toast/ErrorToast";
import SuccessToast from "../../../components/Toast/SuccessToast";

const MentorsProfile = () => {

  const { mentorDetails, isFetching, isSuccess, isError, errorMessage } = useSelector(mentorSliceData);

  const passwordTemplate = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  };

  const validationTemplate = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    error: ""
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Toast states
  const [ErrMessage, setErrMessage] = useState("");
  const [isShowError, setIsShowError] = useState(false);
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  //ChangePass states
  const [changePassToggler, setChangePassToggler] = useState(false);
  const [password, setPassword] = useState(passwordTemplate);
  const [passwordValidation, setPasswordValidation] = useState(validationTemplate);

  useEffect(() => {
    dispatch(getMentorDetails());
  }, []);

  const handlePassChange = (e) => {
    console.log(e.target.name)
    setPasswordValidation(validationTemplate);
    const { name, value } = e.target;
    setPassword(prev => ({
      ...prev,
      [name]: value
    }))
  };

  const validation = () => {
    for (const key in password) {
      if (!password[key]) {
        setPasswordValidation(prev => ({
          ...prev,
          [key]: "Please fill this field"
        }));
        return true;
      }
    };
    if (password.newPassword !== password.confirmPassword) {
      setPasswordValidation(prev => ({
        ...prev,
        error: "Password & Confirm Password do not match"
      }));
      return true;
    }
    return false;
  }

  const handleClickChangePass = () => {
    if (!validation()) {
      const obj = {
        oldPassword: password.oldPassword,
        newPassword: password.newPassword
      }
      dispatch(changePassword(obj));
    }
  };

  const handlePopupClose = () => {
    setChangePassToggler(false);
    setPassword(passwordTemplate);
  };

  const handleKeyPressSubmit = (e) => {
    if (e.key === "Enter") {
      if (!validation()) {
        const obj = {
          oldPassword: password.oldPassword,
          newPassword: password.newPassword
        }
        dispatch(changePassword(obj));
      }
    }
  }

  useEffect(() => {
    if (isError) {
      setIsShowError(true);
      setErrMessage(errorMessage);
      dispatch(clearState());
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      setPassword(passwordTemplate);
      setIsShowSuccess(true);
      setSuccessMessage("Success!")
      setChangePassToggler(false);
      dispatch(clearState());
    }
  }, [isSuccess])

  return (
    <>
      {isFetching && <PageLoader />}
      <ChangePassPopup
        isActive={changePassToggler}
        setIsActive={setChangePassToggler}
        value={password}
        onChange={handlePassChange}
        onClick={handleClickChangePass}
        validation={passwordValidation}
        handleClose={handlePopupClose}
        onKeyPress={handleKeyPressSubmit}
      />
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={ErrMessage}
      />
      <SuccessToast
        show={isShowSuccess}
        setShow={() => setIsShowSuccess(false)}
        message={successMessage}
      />
      <div className="profileWrapper">

        <div className="profileHeader withbutton">
          <h2 className="heading-3">My Profile</h2>
          <Button
            className={"outlineBtn"}
            name={"Edit Profile"}
            onClick={() => { navigate("/editProfile") }}
          />
        </div>

        <div className="profileMentorWrapper">
          <div className="mentorProfile profileMentor">
            <img
              src={
                mentorDetails?.profileImg ?
                  mentorDetails?.profileImg
                  : "https://events.powerathletehq.com/wp-content/uploads/2018/06/grey-person-coming-soon-e1529779699986.jpg"
              }
              alt=""
              className="mentorImg"
            />
            <div className="mentorDetails">
              <p className="mentorName">
                {mentorDetails?.firstName ? `${mentorDetails?.firstName} ${mentorDetails?.lastName}` : "---"}
                {mentorDetails?.linkedin && <div
                  className="linkdinBtn"
                  onClick={() => {
                    window.open("https://" + mentorDetails?.linkedin);
                  }}
                >
                  Linkedin
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.8125 13.75H2.1875"
                      stroke="#3397EF"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.875 2.1875L3.125 10.9375"
                      stroke="#3397EF"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.875 8.60625V2.1875H5.45625"
                      stroke="#3397EF"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>}
              </p>
              <p className="designation">
                {mentorDetails?.GetPrepProfession?.designation ? `${mentorDetails?.GetPrepProfession?.designation}, ${mentorDetails?.GetPrepProfession?.company}` : "---"}
              </p>
            </div>
          </div>

          <div className="accountDetailsWrap mt-30">
            <div className="detailsHeading">Overview</div>
          </div>

          <div class="personalDetailOuter">
            <p class="heading">Personal Details</p>

            <div class="personalDetailContainer mt-15">
              <div class="cardsOuter">
                <div class="card">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.6253 11.5833H14.1374C13.9394 11.5833 13.7458 11.6421 13.5812 11.7523L11.742 12.9833C11.442 13.1833 11.092 13.275 10.7503 13.275C10.4587 13.275 10.167 13.2083 9.90032 13.0583C9.53496 12.8652 9.25786 12.525 9.10179 12.1359C8.93704 11.7252 8.66065 11.3339 8.26685 11.1321C7.92851 10.9586 7.62203 10.7384 7.35866 10.475C6.64199 9.75831 6.25033 8.74998 6.25033 7.62498V4.37498V4.16665C6.25033 3.70641 5.87723 3.33331 5.41699 3.33331C3.16699 3.33331 1.66699 4.45831 1.66699 7.08331V11.5833C1.66699 14.2083 3.16699 15.3333 5.41699 15.3333H8.54199V17.7083H6.16699C5.82533 17.7083 5.54199 17.9916 5.54199 18.3333C5.54199 18.675 5.82533 18.9583 6.16699 18.9583H12.167C12.5087 18.9583 12.792 18.675 12.792 18.3333C12.792 17.9916 12.5087 17.7083 12.167 17.7083H9.79199V15.3333H12.917C14.8585 15.3333 16.2415 14.4957 16.5843 12.5795C16.6816 12.0358 16.2193 11.5833 15.667 11.5833H15.6253Z"
                      fill="#3397EF"
                    ></path>
                    <path
                      d="M15.625 1.66669H10.2083C8.96667 1.66669 8.03333 2.30002 7.66667 3.33335C7.55833 3.65002 7.5 4.00002 7.5 4.37502V7.62502C7.5 8.43335 7.76667 9.11669 8.24167 9.59169C8.71667 10.0667 9.4 10.3334 10.2083 10.3334V11.4917C10.2083 11.9167 10.6917 12.175 11.05 11.9417L13.4583 10.3334H15.625C16 10.3334 16.35 10.275 16.6667 10.1667C17.7 9.80002 18.3333 8.86669 18.3333 7.62502V4.37502C18.3333 2.75002 17.25 1.66669 15.625 1.66669ZM10.5167 6.66669C10.1667 6.66669 9.89167 6.38335 9.89167 6.04169C9.89167 5.70002 10.1667 5.41669 10.5167 5.41669C10.8667 5.41669 11.1417 5.70002 11.1417 6.04169C11.1417 6.38335 10.8667 6.66669 10.5167 6.66669ZM12.8667 6.66669C12.5167 6.66669 12.2417 6.38335 12.2417 6.04169C12.2417 5.70002 12.525 5.41669 12.8667 5.41669C13.2083 5.41669 13.4917 5.70002 13.4917 6.04169C13.4917 6.38335 13.2083 6.66669 12.8667 6.66669ZM15.2083 6.66669C14.8583 6.66669 14.5833 6.38335 14.5833 6.04169C14.5833 5.70002 14.8667 5.41669 15.2083 5.41669C15.55 5.41669 15.8333 5.70002 15.8333 6.04169C15.8333 6.38335 15.55 6.66669 15.2083 6.66669Z"
                      fill="#3397EF"
                    ></path>
                    <path
                      d="M18.3333 4.37502V7.62502C18.3333 8.86669 17.7 9.80002 16.6667 10.1667C16.35 10.275 16 10.3334 15.625 10.3334H13.4583L11.05 11.9417C10.6917 12.175 10.2083 11.9167 10.2083 11.4917V10.3334C9.4 10.3334 8.71667 10.0667 8.24167 9.59169C7.76667 9.11669 7.5 8.43335 7.5 7.62502V4.37502C7.5 4.00002 7.55833 3.65002 7.66667 3.33335C8.03333 2.30002 8.96667 1.66669 10.2083 1.66669H15.625C17.25 1.66669 18.3333 2.75002 18.3333 4.37502Z"
                      fill="#3397EF"
                    ></path>
                  </svg>

                  <div class="cardDetail">
                    <p class="heading">Email</p>
                    <p class="content mt-10">{mentorDetails?.email ? mentorDetails?.email : "---"}</p>
                  </div>
                </div>

                <div class="card">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.20866 12.4584L7.66699 14C7.34199 14.325 6.82533 14.325 6.49199 14.0084C6.40033 13.9167 6.30866 13.8334 6.21699 13.7417C5.35866 12.875 4.58366 11.9667 3.89199 11.0167C3.20866 10.0667 2.65866 9.11669 2.25866 8.17502C1.86699 7.22502 1.66699 6.31669 1.66699 5.45002C1.66699 4.88335 1.76699 4.34169 1.96699 3.84169C2.16699 3.33335 2.48366 2.86669 2.92533 2.45002C3.45866 1.92502 4.04199 1.66669 4.65866 1.66669C4.89199 1.66669 5.12533 1.71669 5.33366 1.81669C5.55033 1.91669 5.74199 2.06669 5.89199 2.28335L7.82533 5.00835C7.97533 5.21669 8.08366 5.40835 8.15866 5.59169C8.23366 5.76669 8.27533 5.94169 8.27533 6.10002C8.27533 6.30002 8.21699 6.50002 8.10033 6.69169C7.99199 6.88335 7.83366 7.08335 7.63366 7.28335L7.00033 7.94169C6.90866 8.03335 6.86699 8.14169 6.86699 8.27502C6.86699 8.34169 6.87533 8.40002 6.89199 8.46669C6.91699 8.53335 6.94199 8.58335 6.95866 8.63335C7.10866 8.90835 7.36699 9.26669 7.73366 9.70002C8.10866 10.1334 8.50866 10.575 8.94199 11.0167C9.02532 11.1 9.11699 11.1834 9.20033 11.2667C9.53366 11.5917 9.54199 12.125 9.20866 12.4584Z"
                      fill="#3397EF"
                    ></path>
                    <path
                      d="M18.3083 15.275C18.3083 15.5083 18.2667 15.75 18.1833 15.9833C18.1583 16.05 18.1333 16.1167 18.1 16.1833C17.9583 16.4833 17.775 16.7667 17.5333 17.0333C17.125 17.4833 16.675 17.8083 16.1667 18.0167C16.1583 18.0167 16.15 18.025 16.1417 18.025C15.65 18.225 15.1167 18.3333 14.5417 18.3333C13.6917 18.3333 12.7833 18.1333 11.825 17.725C10.8667 17.3167 9.90833 16.7667 8.95833 16.075C8.63333 15.8333 8.30833 15.5917 8 15.3333L10.725 12.6083C10.9583 12.7833 11.1667 12.9167 11.3417 13.0083C11.3833 13.025 11.4333 13.05 11.4917 13.075C11.5583 13.1 11.625 13.1083 11.7 13.1083C11.8417 13.1083 11.95 13.0583 12.0417 12.9667L12.675 12.3417C12.8833 12.1333 13.0833 11.975 13.275 11.875C13.4667 11.7583 13.6583 11.7 13.8667 11.7C14.025 11.7 14.1917 11.7333 14.375 11.8083C14.5583 11.8833 14.75 11.9917 14.9583 12.1333L17.7167 14.0917C17.9333 14.2417 18.0833 14.4167 18.175 14.625C18.2583 14.8333 18.3083 15.0417 18.3083 15.275Z"
                      fill="#3397EF"
                    ></path>
                  </svg>
                  <div class="cardDetail">
                    <p class="heading">Phone Number</p>
                    <p class="content mt-10">{mentorDetails?.mobileNumber ? mentorDetails?.mobileNumber : "---"}</p>
                  </div>
                </div>
                <div class="card">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.625 6.66666V8.41666C15.2583 8.36666 14.8417 8.34166 14.375 8.33332V6.66666C14.375 4.04166 13.6333 2.29166 10 2.29166C6.36667 2.29166 5.625 4.04166 5.625 6.66666V8.33332C5.15833 8.34166 4.74167 8.36666 4.375 8.41666V6.66666C4.375 4.24999 4.95833 1.04166 10 1.04166C15.0417 1.04166 15.625 4.24999 15.625 6.66666Z" fill="#3397EF" />
                    <path d="M15.6243 8.41668C15.2577 8.36668 14.841 8.34168 14.3743 8.33334H5.62435C5.15768 8.34168 4.74102 8.36668 4.37435 8.41668C2.24935 8.67501 1.66602 9.71668 1.66602 12.5V14.1667C1.66602 17.5 2.49935 18.3333 5.83268 18.3333H14.166C17.4993 18.3333 18.3327 17.5 18.3327 14.1667V12.5C18.3327 9.71668 17.7493 8.67501 15.6243 8.41668ZM7.25768 13.925C7.09935 14.075 6.88268 14.1667 6.66602 14.1667C6.55768 14.1667 6.44935 14.1417 6.34935 14.1C6.24102 14.0583 6.15768 14 6.07435 13.925C5.92435 13.7667 5.83268 13.55 5.83268 13.3333C5.83268 13.225 5.85768 13.1167 5.89935 13.0167C5.94102 12.9167 5.99935 12.825 6.07435 12.7417C6.15768 12.6667 6.24102 12.6083 6.34935 12.5667C6.65768 12.4333 7.02435 12.5083 7.25768 12.7417C7.33268 12.825 7.39102 12.9167 7.43268 13.0167C7.47435 13.1167 7.49935 13.225 7.49935 13.3333C7.49935 13.55 7.40768 13.7667 7.25768 13.925ZM10.766 13.65C10.7243 13.75 10.666 13.8417 10.591 13.925C10.4327 14.075 10.216 14.1667 9.99935 14.1667C9.77435 14.1667 9.56602 14.075 9.40768 13.925C9.33268 13.8417 9.27435 13.75 9.23268 13.65C9.19102 13.55 9.16602 13.4417 9.16602 13.3333C9.16602 13.1083 9.25768 12.9 9.40768 12.7417C9.71602 12.4333 10.2743 12.4333 10.591 12.7417C10.741 12.9 10.8327 13.1083 10.8327 13.3333C10.8327 13.4417 10.8077 13.55 10.766 13.65ZM13.9243 13.925C13.766 14.075 13.5493 14.1667 13.3327 14.1667C13.116 14.1667 12.8993 14.075 12.741 13.925C12.591 13.7667 12.4993 13.5583 12.4993 13.3333C12.4993 13.1083 12.591 12.9 12.741 12.7417C13.0577 12.4333 13.616 12.4333 13.9243 12.7417C13.9577 12.7833 13.991 12.825 14.0243 12.875C14.0577 12.9167 14.0827 12.9667 14.0993 13.0167C14.1243 13.0667 14.141 13.1167 14.1493 13.1667C14.1577 13.225 14.166 13.2833 14.166 13.3333C14.166 13.55 14.0743 13.7667 13.9243 13.925Z" fill="#3397EF" />
                  </svg>
                  <div class="cardDetail">
                    <div className='changePass'>
                      <p class="heading">Password</p>
                      <span onClick={() => setChangePassToggler(true)}>Change Password</span>
                    </div>
                    <p class="content mt-10">{"******"}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <span className="divider"></span> */}

          <div class="personalDetailOuter topBorderMentor">
            <p class="heading">Personal Details</p>

            <div class="personalDetailContainer mt-15">
              <div class="cardsOuter">
                <div class="card">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5751 5.81676C16.8668 5.03343 15.6834 4.64176 13.9668 4.64176H13.7668V4.60843C13.7668 3.20843 13.7668 1.4751 10.6334 1.4751H9.36678C6.23345 1.4751 6.23345 3.21676 6.23345 4.60843V4.6501H6.03345C4.30845 4.6501 3.13345 5.04176 2.42511 5.8251C1.60011 6.74176 1.62511 7.9751 1.70845 8.81676L1.71678 8.8751L1.77757 9.51338C1.79183 9.66315 1.87258 9.79853 1.99858 9.88076C2.20063 10.0126 2.51037 10.2114 2.70011 10.3168C2.81678 10.3918 2.94178 10.4584 3.06678 10.5251C4.49178 11.3084 6.05845 11.8334 7.65011 12.0918C7.72511 12.8751 8.06678 13.7918 9.89178 13.7918C11.7168 13.7918 12.0751 12.8834 12.1334 12.0751C13.8334 11.8001 15.4751 11.2084 16.9584 10.3418C17.0084 10.3168 17.0418 10.2918 17.0834 10.2668C17.4036 10.0858 17.7355 9.8641 18.0401 9.64542C18.1533 9.56415 18.2255 9.43801 18.2409 9.29951L18.2501 9.21676L18.2918 8.8251C18.3001 8.7751 18.3001 8.73343 18.3084 8.6751C18.3751 7.83343 18.3584 6.68343 17.5751 5.81676ZM10.9084 11.5251C10.9084 12.4084 10.9084 12.5418 9.88344 12.5418C8.85845 12.5418 8.85845 12.3834 8.85845 11.5334V10.4834H10.9084V11.5251ZM7.42511 4.64176V4.60843C7.42511 3.19176 7.42511 2.66676 9.36678 2.66676H10.6334C12.5751 2.66676 12.5751 3.2001 12.5751 4.60843V4.6501H7.42511V4.64176Z"
                      fill="#3397EF"
                    />
                    <path
                      d="M17.2597 11.509C17.6137 11.3417 18.0209 11.6223 17.9855 12.0123L17.7 15.1583C17.525 16.8249 16.8416 18.5249 13.175 18.5249H6.82495C3.15828 18.5249 2.47495 16.8249 2.29995 15.1666L2.02924 12.1888C1.9942 11.8033 2.39252 11.5233 2.74555 11.6819C3.70476 12.113 5.31676 12.8064 6.35274 13.0854C6.51675 13.1295 6.6499 13.2476 6.7243 13.4003C7.23851 14.4555 8.32769 15.0166 9.89162 15.0166C11.4401 15.0166 12.5426 14.4339 13.0588 13.3755C13.1333 13.2227 13.2664 13.1047 13.4304 13.0603C14.5322 12.7618 16.2536 11.9845 17.2597 11.509Z"
                      fill="#3397EF"
                    />
                  </svg>

                  <div class="cardDetail">
                    <p class="heading">Professional interest</p>

                    {mentorDetails?.GetPrepInterests?.length > 0 ? mentorDetails?.GetPrepInterests?.map((val) => (
                      <p class="content mt-10">
                        <li>{val?.interest}</li>
                      </p>
                    )) : "---"}
                  </div>
                </div>

                <div class="card">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 11.6667C5.82505 11.6667 2.42505 14.4667 2.42505 17.9167C2.42505 18.1501 2.60838 18.3334 2.84172 18.3334H17.1584C17.3917 18.3334 17.575 18.1501 17.575 17.9167C17.575 14.4667 14.175 11.6667 10 11.6667Z"
                      fill="#3397EF"
                    />
                    <path
                      d="M13.0918 3.05008C12.3418 2.20008 11.2252 1.66675 10.0002 1.66675C8.8335 1.66675 7.76683 2.14175 7.0085 2.92508C6.2835 3.67508 5.8335 4.70841 5.8335 5.83341C5.8335 6.61675 6.05016 7.35008 6.44183 7.97508C6.65016 8.33341 6.91683 8.65841 7.2335 8.92508C7.9585 9.59175 8.92516 10.0001 10.0002 10.0001C11.5252 10.0001 12.8418 9.18341 13.5668 7.97508C13.7835 7.61675 13.9502 7.21675 14.0418 6.80008C14.1252 6.49175 14.1668 6.16675 14.1668 5.83341C14.1668 4.76675 13.7585 3.79175 13.0918 3.05008ZM11.5585 6.60008H8.44183C8.0085 6.60008 7.6585 6.25008 7.6585 5.81675C7.6585 5.38341 8.0085 5.03341 8.44183 5.03341H11.5585C11.9918 5.03341 12.3418 5.38341 12.3418 5.81675C12.3418 6.25008 11.9918 6.60008 11.5585 6.60008Z"
                      fill="#3397EF"
                    />
                  </svg>

                  <div class="cardDetail">
                    <p class="heading">Mentorship Topic</p>
                    {mentorDetails?.GetPrepTopics?.length > 0 ? mentorDetails?.GetPrepTopics?.map((value, index) => (
                      <p class="content mt-10" key={index}>
                        <li>{value?.Topic}</li>
                      </p>
                    )) : "---"}
                  </div>
                </div>

                <div class="card">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.9999 3.875C6.01657 3.875 2.7749 7.11667 2.7749 11.1C2.7749 15.0833 6.01657 18.3333 9.9999 18.3333C13.9832 18.3333 17.2249 15.0917 17.2249 11.1083C17.2249 7.125 13.9832 3.875 9.9999 3.875ZM10.6249 10.8333C10.6249 11.175 10.3416 11.4583 9.9999 11.4583C9.65824 11.4583 9.3749 11.175 9.3749 10.8333V6.66667C9.3749 6.325 9.65824 6.04167 9.9999 6.04167C10.3416 6.04167 10.6249 6.325 10.6249 6.66667V10.8333Z"
                      fill="#3397EF"
                    />
                    <path
                      d="M12.4084 2.87508H7.5917C7.25837 2.87508 6.9917 2.60841 6.9917 2.27508C6.9917 1.94175 7.25837 1.66675 7.5917 1.66675H12.4084C12.7417 1.66675 13.0084 1.93341 13.0084 2.26675C13.0084 2.60008 12.7417 2.87508 12.4084 2.87508Z"
                      fill="#3397EF"
                    />
                  </svg>

                  <div class="cardDetail">
                    <p class="heading">Years of Experience</p>
                    <p class="content mt-10">{mentorDetails?.GetPrepProfession?.yrsOfExp ? mentorDetails?.GetPrepProfession?.yrsOfExp : "---"}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MentorsProfile;
