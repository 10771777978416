import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import ChangePassPopup from "../../../components/changePassPopup/ChangePassPopup";
import PageLoader from "../../../components/PageLoader/PageLoader";
import ErrorToast from "../../../components/Toast/ErrorToast";
import SuccessToast from "../../../components/Toast/SuccessToast";
import {
  clearState,
  getAllPastCalls,
  getAllUpcomingCalls,
  mentorSliceData,
  changePassword,
  updateMentorJoiningStatus
} from "../../../reducer/mentorSlice";
import "./CallsBookingStyle.css";

const CallsBooking = () => {
  const { allUpcomingCalls, allPastCalls, isFetching, isSuccess, isError, errorMessage } =
    useSelector(mentorSliceData);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [errMessage, seterrMessage] = useState("");
  const [isShowError, setIsShowError] = useState(false);

  const testTime = (time) => {
    const today = moment();
    const callTime = moment(time);
    const diff = callTime.diff(today, "minutes");
    if ((diff - 330) <= 10) return false;
    else return true;
  }

  const passwordTemplate = {
    oldPassword: "123456",
    newPassword: "",
    confirmPassword: ""
  };

  const validationTemplate = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    error: ""
  }

  //Toast states
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  //ChangePass states
  const [changePassToggler, setChangePassToggler] = useState(false);
  const [password, setPassword] = useState(passwordTemplate);
  const [passwordValidation, setPasswordValidation] = useState(validationTemplate);

  const handlePassChange = (e) => {
    setPasswordValidation(validationTemplate);
    const { name, value } = e.target;
    setPassword(prev => ({
      ...prev,
      [name]: value
    }))
  };

  const validation = () => {
    for (const key in password) {
      if(key === "oldPassword") continue;
      if (!password[key]) {
        setPasswordValidation(prev => ({
          ...prev,
          [key]: "Please fill this field"
        }));
        return true;
      }
    };
    if (password.newPassword !== password.confirmPassword) {
      setPasswordValidation(prev => ({
        ...prev,
        error: "Password & Confirm Password do not match"
      }));
      return true;
    }
    if (password.newPassword === "123456") {
      setPasswordValidation(prev => ({
        ...prev,
        error: "New password must be different"
      }));
      return true;
    }
    return false;
  }

  const handleClickChangePass = () => {
    if (!validation()) {
      const obj = {
        oldPassword: password.oldPassword,
        newPassword: password.newPassword
      }
      dispatch(changePassword(obj));
    }
  };

  const handlePopupClose = () => {
    setChangePassToggler(false);
    setPassword(passwordTemplate);
  };

  const handleKeyPressSubmit = (e) => {
    if (e.key === "Enter") {
      if (!validation()) {
        const obj = {
          oldPassword: password.oldPassword,
          newPassword: password.newPassword
        }
        dispatch(changePassword(obj));
      }
    }
  }

  useEffect(() => {
    if (!toggle) {
      dispatch(getAllUpcomingCalls());
    } else {
      dispatch(getAllPastCalls());
    }
  }, [toggle]);

  useEffect(() => {
    if (isError) {
      setIsShowError(true);
      seterrMessage(errorMessage);
      dispatch(clearState());
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      setPassword(passwordTemplate);
      setIsShowSuccess(true);
      setSuccessMessage("Success!")
      setChangePassToggler(false);
      localStorage.setItem("isPassChanged","true");
      dispatch(clearState());
    }
  }, [isSuccess])

  useEffect(() => {
    dispatch(getAllPastCalls());
    if(localStorage.getItem("isPassChanged") === "false") {
      setChangePassToggler(true);
    }
  }, [])


  //Checking for empty feedback
  const completedCalls = allPastCalls?.filter(item => item.isCompleted);
  completedCalls?.forEach((item) => {
    const mentorPendingFeedback = item?.getPrepFeedbackAnswers?.filter((feedback) => feedback?.mentorId);
    if (mentorPendingFeedback?.length === 0) navigate(`/createFeedback?id=${item.id}`);
  });

  const onClickStartBtn = (value)=>{
    // const obj ={
    //   status : "true",
    //   meetingId : value?.id
    // }
    // dispatch(updateMentorJoiningStatus(obj))
    window.open(value?.mentorMeetingLink)
  }

  return (
    <>
      {isFetching && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <SuccessToast
        show={isShowSuccess}
        setShow={() => setIsShowSuccess(false)}
        message={successMessage}
      />
      <ChangePassPopup
        isActive={changePassToggler}
        setIsActive={setChangePassToggler}
        value={password}
        onChange={handlePassChange}
        onClick={handleClickChangePass}
        validation={passwordValidation}
        handleClose={handlePopupClose}
        onKeyPress={handleKeyPressSubmit}
      />
      <div className="bookingsWrapper">
        <div className="profileHeader">
          <h2 className="heading-3">Upcoming Calls</h2>
        </div>

        <div className="CallsWrap">
          <div className="callTypes">
            <Button
              className={`callButton  ${!toggle && "active"} `}
              name={"Upcoming Calls"}
              onClick={() => {
                setToggle(false);
              }}
            />
            <Button
              className={`callButton  ${toggle && "active"} `}
              name={"Past Calls"}
              onClick={() => {
                setToggle(true);
              }}
            />
          </div>
          {(toggle ? allPastCalls : allUpcomingCalls)?.length > 0
            ? (toggle ? allPastCalls : allUpcomingCalls)?.map(
              (value, index) => (
                <div className="scheduleData mt-20" key={index}>
                  <div className="callersBox">
                    <div className="callersBoxLeft">
                      <img src={value?.GetPreppedMentee?.profileImg ? value?.GetPreppedMentee?.profileImg : "/assets/img/profiledefault.png"} alt="" />
                      <div className="callDetail">
                        <div className="tabCall active">
                          <div className="callerName">
                            {value.GetPreppedMentee?.firstName ? `${value.GetPreppedMentee?.firstName} ${value.GetPreppedMentee?.lastName}` : "---"}
                          </div>
                          <div className="callerEmail mt-5">
                            {value.GetPreppedMentee?.email ? value.GetPreppedMentee?.email : "---"}
                          </div>
                          <div className="callDetails mt-10">
                            <div className="callBox greenCall">
                              {value?.startTime ? moment(value?.startTime).utcOffset("-00:00").format(
                                "ddd DD MMM YYYY"
                              ) : "---"}
                            </div>
                            <div className="callBox blueCall">
                              {value?.startTime ? moment(value?.startTime).utcOffset("-00:00").format(
                                "h:mm a"
                              ) : "---"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="callerBoxRight" style={toggle ? { display: "none" } : null}
                    >
                      <Button
                        className={`CallButton ${!testTime(value?.startTime) && "active"}`}
                        disabled={testTime(value?.startTime)}
                        name={"Start Call"}
                        onClick={() => onClickStartBtn(value) }
                      />
                    </div>
                  </div>
                </div>
              )
            )
            : <div className="scheduleData mt-20">No calls yet</div>}
        </div>
      </div>
    </>
  );
};

export default CallsBooking;
