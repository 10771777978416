import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button/Button";
import "./MentorFeedbackStyle.css";
import PageLoader from "../../../components/PageLoader/PageLoader";
import {
  getFeedbackAnswers,
  getSpecficMentorFeedback,
  mentorSliceData,
} from "../../../reducer/mentorSlice";
import { useParams } from "react-router-dom";

const ViewFeedback = () => {
  const [toggle, setToggle] = useState(false);
  const [menteeFeedback, setMenteeFeedback] = useState([]);
  const [mentorFeedback, setMentorFeedback] = useState([]);

  const { feedbackAnswers, isFetching } = useSelector(mentorSliceData);
  const dispatch = useDispatch();
  const { id } = useParams();
  const ratingArray = [1, 2, 3, 4, 5];

  useEffect(() => {
    dispatch(getFeedbackAnswers(id));
  }, []);

  useEffect(() => {
    setMentorFeedback(
      feedbackAnswers?.getPrepFeedbackAnswers?.filter((item) => item.mentorId !== null)
    );
    setMenteeFeedback(
      feedbackAnswers?.getPrepFeedbackAnswers?.filter((item) => item.menteeId !== null)
    );
  }, [feedbackAnswers]);

  return (
    <>
      {isFetching && <PageLoader />}
      <div className="viewFeedbackWrappper">
        <div className="profileHeader">
          <h2 className="heading-3">Feedback Received / {feedbackAnswers?.GetPreppedMentee?.firstName ? `${feedbackAnswers?.GetPreppedMentee?.firstName} ${feedbackAnswers?.GetPreppedMentee?.lastName}` : "---"}</h2>
        </div>

        <div className="FeedbackWrap">
          <div className="callTypes">
            <Button
              className={`callButton ${!toggle && "active"}`}
              name={"Mentee Feedback"}
              onClick={() => {
                setToggle(false);
              }}
            />
            <Button

              className={`callButton ${toggle && "active"}`}
              name={"Your Feedback"}
              onClick={() => {
                setToggle(true);
              }}
            />
          </div>
          <div className="scheduleData mt-30">
            <div className={`tabCall ${!toggle && "active"}`}>
              {menteeFeedback?.length > 0
                ? menteeFeedback?.map((item, index) => (
                    <React.Fragment key={index}>
                      <div className="feedbackBoxAll">
                        <div className="feedbackQuestion">
                          How was your experience with the mentor?
                        </div>
                        <div className="ratingScale mt-15">
                          <span className="useless">0</span>
                          {ratingArray?.map((ratingArray, index) => (
                            <span
                              key={index}
                              className={
                                +item?.answer1 >= ratingArray && "active"
                              }
                            >
                              {ratingArray}
                            </span>
                          ))}
                        </div>
                      </div>
                      <div className="feedbackBoxAll mt-20">
                        <div className="feedbackQuestion">
                          Did the call help you get any clarity?
                        </div>
                        <div className="feedbackAnswer mt-5">{item?.answer2}</div>
                      </div>

                      <div className="feedbackBoxAll mt-20">
                        <div className="feedbackQuestion">
                          Would you like to schedule a call again with the same
                          mentor?
                        </div>
                        <div className="feedbackAnswer mt-5">{item?.answer3}</div>
                      </div>

                      <div className="feedbackBoxAll mt-20">
                        <div className="feedbackQuestion">
                          Was the mentor able to answer your questions?
                        </div>
                        <div className="feedbackAnswer mt-5">{item?.answer4}</div>
                      </div>
                    </React.Fragment>
                  ))
                : "No feedback received"}
            </div>
          </div>

            <div className={`tabCall ${toggle && "active"}`}>
            { mentorFeedback?.length > 0 ? mentorFeedback?.map((item, index) =>(
            <React.Fragment>
              <div className="feedbackBoxAll">
                <div className="feedbackQuestion">
                  How was your experience with the mentee?
                </div>
                <div className="ratingScale mt-15">
                  <span className="useless">0</span>
                  {ratingArray.map((ratingArray, index) => (
                            <span
                              key={index}
                              className={
                                +item?.answer1 >= ratingArray && "active"
                              }
                            >
                              {ratingArray}
                            </span>
                          ))}
                </div>
              </div>

              <div className="feedbackBoxAll mt-20">
                <div className="feedbackQuestion">
                  What was the mentee’s problem about?
                </div>
                <div className="feedbackAnswer mt-5">
                  {item?.answer2}
                </div>
              </div>

              <div className="feedbackBoxAll mt-20">
                <div className="feedbackQuestion">
                  Were you able to come up with an action plan for the mentee?
                </div>
                <div className="feedbackAnswer mt-5">{item?.answer3}</div>
              </div>

              <div className="feedbackBoxAll mt-20">
                <div className="feedbackQuestion">
                  How would you rate the mentee’s employability, if you were to
                  hire them for your own team?
                </div>
                <div className="ratingScale mt-15">
                  <span className="useless">0</span>
                  {ratingArray?.map((ratingArray, index) => (
                    <span key={index} className={+item?. answer4 >= ratingArray && "active"}>
                      {ratingArray}
                    </span>
                  ))}
                </div>
              </div>
              </React.Fragment>
              )):"Feedback not given"}
            </div>
        </div>
      </div>
    </>
  );
};

export default ViewFeedback;
